import { Record } from 'immutable'

const DatasourceUpdateRecord = Record({
  accountingPeriodType: undefined,
  companyPresentationType: undefined,
  companies: undefined,
  dataType: undefined,
  dimensionPresentationType: undefined,
  id: undefined,
  name: undefined,
  order: undefined,
  periodType: undefined,
  dynamicEndDateType: undefined,
  dynamicStartDateType: undefined,
  includeDynamicStartMonth: undefined,
  monthsFromDynamicStartDate: undefined,
  periodPresentationType: undefined,
  start: undefined,
  end: undefined,
  formula: undefined,
  formulaSelections: undefined,
  showTopRowsCount: undefined,
  reportSchemeId: undefined,
})

export default DatasourceUpdateRecord
