import { Record, List } from 'immutable'

class ReportRecord extends Record({
  currencyCode: undefined,
  dateFormat: '',
  decimals: undefined,
  decimalSeparatorType: undefined,
  id: undefined,
  name: undefined,
  ownerId: undefined,
  // percentageTargetId: undefined,
  precisionType: undefined,
  reportDataSources: undefined,
  reportSchemeId: undefined,
  reportSelectionUsers: undefined,
  showPercentageColumn: undefined,
  showSubTotals: undefined,
  showTotalColumn: undefined,
  showInRows: undefined,
  showInColumns: undefined,
  includeLastSchemeLevelAsDataRow: false,
  thousandSeparatorType: undefined,
  type: 0,
  useOverlappingMonths: undefined,
  userId: undefined,
  visualizationOverride: undefined,
  reportSelectionTagIds: List(),
  customerId: undefined,
  hideToolbar: false,
}) {
  constructor(params) {
    super({
      ...params,
      // FIXME: This can be removed once backend returns this as JSON object
      // instead of a String
      visualizationOverride: params.visualizationOverride
        ? JSON.parse(params.visualizationOverride)
        : undefined,
    })
  }
}

export default ReportRecord
