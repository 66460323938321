import { useSelector } from 'react-redux'
import { selectUserTheme } from 'containers/App/selectors'
import { useEffect } from 'react'
const DEFAULT_THEME = 'light'

const useSetThemeToBody = () => {
  const theme = useSelector(selectUserTheme)
  useEffect(() => {
    document.body.classList.remove('light')
    document.body.classList.remove('dark')
    document.body.classList.add(theme || DEFAULT_THEME)
  }, [theme])
}

export default useSetThemeToBody
