import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { JsErrorComponent } from 'components/ErrorComponent/ErrorComponents'
import Dialogs from 'containers/Dialogs'
import NavigationBar from 'containers/NavigationBar'
import DevelopmentInfo from '../../components/DevelopmentInfo'

import isDevelopment from 'utils/isDevelopment'
import { isLocalhost } from 'utils/isLocalHost'

import { useLocation } from 'react-router'
import { Outlet } from 'react-router-dom'
import LoadingIndicator from 'components/LoadingIndicator'
import { useSelector } from 'react-redux'
import { selectUserTheme } from 'containers/App/selectors'
import { Keys } from 'api/TokenStorage'
import useSetThemeToBody from 'utils/hooks/useSetThemeToBody'

window.addEventListener('storage', (e) => {
  if (
    (e.key === Keys.PROFILE_ID && e.newValue !== e.oldValue) ||
    (e.key === Keys.ACCESS_TOKEN && (!e.newValue || !e.oldValue))
  ) {
    window.location.reload()
  }
})

function LoggedInUser(props) {
  const theme = useSelector(selectUserTheme)
  const location = useLocation()
  useSetThemeToBody()
  return (
    <div className={theme}>
      {isLocalhost && isDevelopment && <DevelopmentInfo />}
      <ErrorBoundary FallbackComponent={JsErrorComponent}>
        <NavigationBar {...props} />
        <ErrorBoundary
          FallbackComponent={JsErrorComponent}
          resetKeys={[location]}
        >
          <React.Suspense fallback={<LoadingIndicator />}>
            <Outlet />
          </React.Suspense>
        </ErrorBoundary>
      </ErrorBoundary>
      <Dialogs />
    </div>
  )
}

export default LoggedInUser
